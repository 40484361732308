/* starts, react-credit-cards */
.react-credit-cards {
  .form-control {
    padding-top: 11px;
    > div:first-child{
      display: block;
    }
    .c-feild {
      padding: 0 0 0 0;
      background-color: transparent;
      +p{
        position: absolute;
        bottom: -20px;
        left: 0;
        color: #fa6160;
        font-size: 12px;
      }
      .c-card-image {
        display: none;
      }
      label{
        margin: 0;
        min-height: 14px;
        &:first-of-type{
          flex-grow: 1;
        }
        &:nth-child(3){
          min-width: 40px;
          margin: 0 6px;
        }
        input{
          padding:0;
          line-height: 14px;
          background-color: transparent;
          &::-moz-placeholder {
            color: #B5B5C3;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color:#B5B5C3;
          }
          &::-webkit-input-placeholder {
            color:#B5B5C3;
          }
        }
      }
      &.is-invalid{
        border-width: 0;
      }
    }
  }
}
/* end, react-credit-cards */
