// PaymentInstrumentModal

.payment-instrument-modal {

  .payment-methods {
    &.tsys {
      .c-separator {
        display: flex;
      }
    }
  }
}
