// buttons

.btn{
    &.btn-sm{
      padding: 6.6px 9px;
      line-height: 15px;
    }
    &.btn-primary-outline{
        background-color: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }
}