div[class*="layout-"] {
  .interac-pay-button {
    margin-top: 12px;
    .btn {
      &.btn-primary {
        padding-left: 6px;
        padding-right: 6px;
        &.btn-interac {
          background-color: #fdba0c !important;
          border-color: #fdba0c !important;
          color: black;
          &:hover,
          &:active {
            color: black;
          }
          .svg-icon {
            height: 24px;
            width: 40px;
            position: relative;
            z-index: 0;
            svg{
              height: 40px;
              width: 40px;
              position: absolute;
              top:-8px;
              left: 3px;
            }
            .st0 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #fdb913;
            }
            .st1 {
              fill-rule: evenodd;
              clip-rule: evenodd;
            }
            #layer {
              enable-background: new 0 0 652 652;
            }
          }
        }
      }
    }
  }
}
