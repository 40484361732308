/* starts, layout modern */

$bg-color: rgba(33, 33, 33, 0.36) !important;

.layout-modern {
  .input-group-text,
  .text-dark {
    &:not(.skip){
      color: #fff !important;
    }
  }
  .c-separator {
    hr {
      border-color: #fff;
    }
  }
  .switch input:empty ~ span:before {
    background-color: $bg-color;
    border: 1px solid #fff;
  }
  .login-signin span.label {
    &:not(.skip){
      background-color: $bg-color;
    }
  }
  .form-group label {
    color: #fff;
  }

  .input-group {
    border: 1px solid #fff;
    border-radius: 24px;

    .form-control {
      border-radius: 24px;
    }

    .input-group-text {
      background-color: rgba(33, 33, 33, 0.36) !important;
    }

    .form-control {
      padding-bottom: 1rem !important;
      padding-top: 1rem !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      color: #ffffff !important;

      &:not(.c-selectbox) {
        opacity: 0.7 !important;
        background-color: rgba(33, 33, 33, 0.36) !important;
      }
    }

    &.with-prepend .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.with-append .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .form-control {
      &::-moz-placeholder {
        color: #b5b5c3;
        opacity: 1;
      }

      &:-ms-input-placeholder,
      &::-webkit-input-placeholder {
        color: #b5b5c3;
      }
    }

    .svg-icon{
      svg{
        g{
          [fill]{
            fill:#fff;
          }
        }
      }
    }

  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px rgba(33, 33, 33, 0.9) inset !important;
    -webkit-text-fill-color: #fff;
    border-radius: 24px;
    background-color: transparent !important;

    /* font-family: Poppins, Helvetica, "sans-serif" !important;
    font-size: 12px !important; */

    &:hover,
    &:focus,
    &:active,
    &::selection {
      -webkit-box-shadow: 0 0 0 100px rgba(33, 33, 33, 0.9) inset !important;
      -webkit-text-fill-color: #fff;
      border-radius: 24px;
      background-color: transparent !important;

      /* font-family: Poppins, Helvetica, "sans-serif" !important;
      font-size: 12px !important; */
    }
  }

  .checkbox > input:checked ~ span {
    background-color: #ffffff !important;
    border-color: #ffffff;

    &:after {
      border-color: #464e5f;
    }
  }

  .btn {
    &.btn-primary,
    &.btn-secondary {
      padding: 0.75rem 3.75rem;
      border-radius: 2rem;
    }

    &.btn-primary {
      min-height: 44px;
    }
  }

  .form-group {
    .c-selectbox {
      border-radius: 24px;
    }

    .form-control.c-selectbox {
      padding: 0px !important;
      background: transparent;
    }

    .c-selectbox div {
      &[class$="-control"] {
        background: transparent;
        border: 0;
        box-shadow: none;
        opacity: 0.7 !important;
        background-color: rgba(33, 33, 33, 0.36) !important;
        border-radius: 24px;
        padding: 6px;
      }
      
      &[class$="-menu"] {
        margin-top: 6px;
        background-color: rgba(33, 33, 33, 0.65) !important;
        border:1px solid rgba(255, 255, 255, 1) !important;
        border-radius:24px;
        [class$="-option"] {
          color: #ffffff;
        }
        .react-select__menu-list {
          border-radius:24px;
        }
        .react-select__option--is-selected,
        .react-select__option--is-focused{
          background-color: rgba(33, 33, 33, 0.85) !important;  
        }
      }

      &[class$="-singleValue"] {
        color: white;
      }

      &[class$="-placeholder"] {
        color: #b5b5c3;
      }

      &[class$="-ValueContainer"] {
        color: white;
        padding: 0 10px !important;
      }
    }
  }

  .login-signin {
    // position: relative;
    // z-index: 0;

    span.label {
      border-radius: 24px;
    }
  }

  .subscription {
    padding: 2rem;
    background-color: rgba(33, 33, 33, 0.36);
    border: 1px solid #fff;
    border-radius: 24px;
  }

  .google-pay-button,
  .apple-pay-button {
    min-height: 47.5px;
  }

  /* starts, react-credit-cards */
  .react-credit-cards {
    .form-control {
      padding-right: 2px !important;
    }
  }
  /* end, react-credit-cards */

}

/* end, layout modern*/
