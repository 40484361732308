body{
  background-color: #fff;
}
.mx-height-px-34{
  max-height: 34px;
}
/* Settings Page */

.wizard-step {
  cursor: pointer;
}

.pre {
  white-space: pre-wrap!important;
}

/* Payment-01 Page */

.input-group.with-prepend .rounded-lg {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}

.input-group.with-append .rounded-lg {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}

.input-group-payment-form {
  border: none;
}

.center-vertical-horizontal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.center-vertical-horizontal a {
  display: block;
  margin: 0 auto;
}

.payment-aside {
  position: relative;
  height: 100%
}

.center-horizontal-bottom {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -90%);
  width: 100%
}

.display-inline {
  display: inline-block!important;
}

@media (max-width: 1030px) {
  .center-vertical-horizontal {
    position: inherit;
  }
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 1030px) {
  .hide-desktop {
    display: none;
  }
}

/* Payment-02 Page */

.input-group.with-prepend .rounded-pill {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}

.input-group.with-append .rounded-pill {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}

.input-group-payment-form {
  border: none;
}

/* .input-group-append.input-group-pill .input-group-payment-form {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
} */

.jp-card .jp-card-front, .jp-card .jp-card-back {
  background: url('../media/card-bg.jpg')!important;
  background-size: cover!important;
}

/* Requests */

#payment-requests table {
  overflow-x: auto;
}

.datatable.datatable-default>.datatable-pager>.datatable-pager-nav>li>.datatable-pager-link {
  border-radius: 100%;
}

.splink-layout-box {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover!important;
}

.input-in-iframe {
  height: 45px;
  padding: 13px 26px;
  border: 1px solid #FFF;
  border-radius: 24px;
  background-color: rgba(33, 33, 33, 0.36) !important;
}

.input-group-border {
  border: 1px solid #FFF;
  border-radius: 24px;
}

/* #cc-card.input-in-iframe{
  width: 29%;
}

#cc-exp.input-in-iframe{
  width: 7%;
}

#cc-card.input-in-iframe,
#cc-exp.input-in-iframe,
#cc-cvv.input-in-iframe{
  padding:13px 10px!important;
} */

/* starts, different layouts */


/* end, layout modern */

.pay-buttons{
  cursor: pointer;
  width: 80px;
  height: 52px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 auto;
  background-color:  rgba(0,0,0,1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: white;
  line-height: 1;
}
.pay-buttons:hover{
  border-color: rgba(255,255,255,0.7);
}
.pay-buttons.bg-icon {
  background-image: url('../media/payment-methods/card_white.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:70% auto;
}
p.payment-method-title,
p.payment-method-separator {
  font-size: 8px;
  opacity: 0.7;
  margin-top: 2px;
  margin-bottom: 0px;
}
p.payment-method-separator {
  font-size: 12px;
}
/* starts, layout classic */


@media (min-width: 992px){
  .login-1 .login-aside{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 992px){
  .login-1{
    padding-left: 450px;
  }
  .login.login-1 .login-aside {
    max-width: 450px;
  }
  .layout-activate .fixed-bg-image,
  .layout-activate .fixed-bg-color{
    max-width: 450px;
  }
}
@media (min-width: 1400px) {
  .login-1{
    padding-left: 600px;
  }
  .login.login-1 .login-aside {
    max-width: 600px;
  }
  .layout-activate .fixed-bg-image,
  .layout-activate .fixed-bg-color{
    max-width: 600px;
  }
}
/* end, layout classic */

/* starts, layout retro activate */
.layout-activate{}
.layout-activate .input-group-text{
  background-color: #EBEFF2;
}

.layout-activate .activate-mask{
  z-index: 1;
  background-color: rgba(0,0,0,0.1);
}
.layout-activate .form-group .form-control.c-selectbox{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.layout-activate .form-group .c-selectbox div[class$="-control"]{
  padding-bottom: 4.5px !important;
  padding-top: 4.5px !important;
}
@media (max-width: 991px){
  .layout-activate .layout-retro{
    position: relative;
    z-index: 0;
  }
  .layout-activate .layout-retro .fixed-bg-image,
  .layout-activate .layout-retro .fixed-bg-color {
    position: absolute;
    bottom: 0;
    max-height: none;
  }
  .layout-activate .login.login-1 .login-aside {
    display: none;
  }
}
@media (min-width: 992px){
  .layout-activate .activate-mask{
    box-shadow: inset -8px 0 10px -6px rgba(00,00,00,1);
  }
}
@media (min-width: 992px){

  .layout-activate .fixed-bg-image,
  .layout-activate .fixed-bg-color,
  .layout-activate .login.login-1 .login-aside{
    max-width: 100vw;
  }
  .layout-activate .login.login-1 .login-aside{
    z-index: 1;
  }
  .layout-activate .fixed-bg-image,
  .layout-activate .fixed-bg-color,
  .layout-activate .login.login-1 .login-aside{
    animation: shrinkSplink 300ms 1.5s forwards;
  }
  .layout-activate{
    overflow-x: hidden;
  }
  .layout-activate .login-1 {
    padding-left: 100vw;
  }
  .layout-activate .login.login-1 .login-content{
    width: 40vw;
  }
  .layout-activate .login-1 {
    animation: activateForm 300ms 1.5s forwards;
  }

}

@keyframes shrinkSplink {
  from { max-width: 100vw; }
  to { max-width: 60vw; }
}

@keyframes activateForm {
  from { padding-left: 100vw; }
  to { padding-left: 60vw; }
}
/* end, layout retro activate */
/* end, different layouts */

/* starts, google + apple + klarna pay button */
.google-pay-button-container,
.google-pay-button,
.apple-pay-button,
.klarna-pay-button {
  border:1px solid #3C4043;
  background-color: #fff;
  width: 100%;
  border-radius: 24px;
  padding: 0;
}

.klarna-pay-button {
  background-color: #000;
  padding: 10px 6px 10px 6px;
}

.klarna-pay-button span {
  color: white;
}

.google-pay-button svg,
.apple-pay-button svg {
  width: 80px;
  height: 36px;
}

.layout-modern .google-pay-button #border #wihit-fill {
  fill: black;
}
.layout-modern .google-pay-button #border #Fill-1 {
  fill: black;
}

/* ends, google + apple pay button */

/* starts, modern apple pay button */
.layout-modern .apple-pay-button #XMLID_3_ {
  fill: black;
}
.layout-modern .apple-pay-button #XMLID_4_ {
  fill: black;
}
.layout-modern .apple-pay-button #Logo,
.layout-modern .apple-pay-button #Pay {
  fill: white;
}
.layout-modern p.payment-method-title,
.layout-modern p.payment-method-separator
 {
  color: #e5e5e5;
}
/* ends, modern apple pay button */

/* starts, Error Modal  */
.swal2-icon.swal2-error{
  border-color: #fa6160;
  color: #fa6160;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line]{
  background-color: rgb(250, 97, 96, 0.75);
}
/* end, Error Modal  */

/* starts, success Modal  */
.swal2-icon.swal2-success [class^=swal2-success-line]{
  background-color:rgb(106, 168, 79);
}
.swal2-icon.swal2-success .swal2-success-ring{
  border-color:rgb(106, 168, 79, 0.3);
}
.swal2-icon.swal2-success .swal2-success-circular-line-left,
.swal2-icon.swal2-success .swal2-success-fix,
.swal2-icon.swal2-success .swal2-success-circular-line-right{
  background-color: rgb(255, 255, 255);
}
/* end, success Modal  */

/* starts, google-suggestion-list */
.google-suggestion-list{
  left:0;right:0;
  background-color: white;
  box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  color: black;
}
.google-suggestion-list .google-suggestion-item{
  cursor: default;
  padding: 8px 12px;
  margin-bottom: 0;
}
.google-suggestion-list .google-suggestion-item:hover{
  background-color: #F5F5F5;
}
.google-suggestion-list .google-suggestion-item.google-suggestion-item-manual{
  color: #fa6160;
  text-decoration: underline;
  cursor: pointer;
}
/* end, google-suggestion-list */

/* starts, payment loading */
.payment-form:not(.payment-form-loading) .btn-paynow .loading{
  display: none;
}
.payment-form.payment-form-loading:after{
  position: absolute;
  background-color: rgba(0,0,0,0);
  top:0;left:0;right:0;bottom:0;
  content: "";
}
/* end, payment loading */


.input-group-prepend-bg-dark-error span {
  background: #fa6160 !important;;
  color: white;
}

.input-group-prepend-bg-green span {
  background: #37DB70;
  color: white;
}

/* starts : Tour step */
.tour-mask-hidden {
  color: transparent;
}

.tour-mask-visible {
  color: rgba(0,0,0,0.15);
}

.tour-step h3 {
  color: white;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.46em
}

.tour-step h3,
.tour-step p {
  line-height: 1.1;
}

.tour-step p {
  font-size: 15px;
}

span[data-tour-elem="badge"] {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-family: sans-serif;
}
.tour-step-badge {
  color: white;
  font-size: 22px;
  line-height: 1.4
}

nav[data-tour-elem="navigation"] {
  margin-top: 15px;
}

.tour-step .material-icons{
  position: absolute;
  top:5px;
  left:-36px;
  font-size:80px;
  font-weight: bold;
  color: #6aa84f;
}
.tour-step.arrow_drop_down .material-icons{
  bottom:-36px;
  right:5px;
  left: auto;
  top: auto;
}
.tour-step.arrow_right .material-icons{
  top: -17px;
  right:-46px;
  left: auto;
}
.tour-step.arrow_drop_up .material-icons{
  top:-36px;
  right:5px;
  left: auto;
}
.tour-nav-button {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 100;
  height: 30px;
  color: #333;
}
div[data-tour-elem="controls"] button[data-tour-elem="left-arrow"],
div[data-tour-elem="controls"] button[data-tour-elem="right-arrow"] {
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  border: solid;
  border-width: 1px;
  height: 33px;
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 12px;
  border-color: #333;
}
div[data-tour-elem="controls"] button[data-tour-elem="left-arrow"][disabled],
div[data-tour-elem="controls"] button[data-tour-elem="right-arrow"][disabled] {
  border-color: #e5e5e5;
}
div[data-tour-elem="controls"] button[data-tour-elem="left-arrow"][disabled] .tour-nav-button,
div[data-tour-elem="controls"] button[data-tour-elem="right-arrow"][disabled] .tour-nav-button {
  color: #e5e5e5;
}
div[data-tour-elem="controls"] {
    justify-content: center !important;
}
/* end : Tour step */

.tour-container {
  background-color: unset !important;
  padding: 0 !important;
  box-shadow: unset !important;
  animation: appear 0.3s;
}

.tour-content {
  background-color: #6aa84f !important;
  padding: 13px;
  border-radius: 8px;
  box-shadow: 0 0.5em 3em rgba(0,0,0,0.3);
}

.tour-shake .tour-content {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s 0.5s;
}

.activate-form-explainer {
  color: #6aa84f;
  font-size: 18px
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes appear {
  from { opacity: 0 }
  to { opacity: 1 }
}

#input-splink .input-group-append,
#input-splink .input-group-append span {
  min-width: 200px;
}

.payment-form-loading-overlay {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.acs-method-form,
.acs-method-form iframe {
  width: 0 !important;
  height: 0 !important;
  margin-bottom: -6px;
}

.grecaptcha-badge { visibility: hidden; }

.form-tsys #tsep-cardHolderName {
  width: 100%
}
.tooltip-password {}

.sub-modal {
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.15s linear;
}

#boi-payment-form,
#boi-payment-form iframe {
  min-height: 290px;
}


.token-bank-account {
  color: #464E5F;
}

.token-bank-account:hover {
  color: white;
}

.token-bank-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  padding: 2px;
  border-width: 1px;
  border-radius: 25px;
  border-color: #e5e5e5;
  border-style: solid;
  overflow: hidden;
  background-color: white;
}
