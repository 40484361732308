/* starts, layout classic */

.layout-classic {
  .form-group {
    .input-group-prepend {
      .input-group-text {
        border-top-left-radius: 0.85rem !important;
        border-bottom-left-radius: 0.85rem !important;
      }
    }
    .input-group-append {
      .input-group-text {
        border-top-right-radius: 0.85rem !important;
        border-bottom-right-radius: 0.85rem !important;
      }
    }
  }

  .input-group {
    border: 1px solid #f3f6f9;
    border-radius: 0.85rem !important;

    .form-control {
      padding-bottom: 1.75rem !important;
      padding-top: 1.75rem !important;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
      border-radius: 0.85rem !important;
      background-color: #f3f6f9;
      border-color: #f3f6f9;
      color: #464e5f;
      -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
        -webkit-box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease,
        -webkit-box-shadow 0.15s ease;
    }

    &.with-prepend .form-control {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    &.with-append .form-control {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .form-control {
      &::-moz-placeholder {
        color: #b5b5c3;
        opacity: 1;
      }

      &:-ms-input-placeholder,
      &::-webkit-input-placeholder {
        color: #b5b5c3;
      }
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #f3f6f9 inset;
    -webkit-text-fill-color: #464e5f;

    &:hover,
    &:focus,
    &:active {
      -webkit-box-shadow: 0 0 0 100px #f3f6f9 inset;
      -webkit-text-fill-color: #464e5f;
    }
  }

  .input-group .form-control {
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: #ecf0f3;
      border-color: #ecf0f3;
      color: #464e5f;
      -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
        -webkit-box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease,
        -webkit-box-shadow 0.15s ease;
    }
  }

  .btn {
    &.btn-primary,
    &.btn-secondary {
      font-weight: 600 !important;
      font-size: 1.175rem !important;
      padding: 1rem 2rem;
    }

    &.btn-primary {
      background-color: #3699ff;
      border-color: #3699ff;
      min-height: 54px;
    }
  }

  .form-group .c-selectbox {
    padding-bottom: 0.65rem !important;
    padding-top: 0.65rem !important;
    padding-left: 0 !important;
    padding-right: 2.5px !important;

    div {
      &[class$="-control"] {
        background: transparent;
        border: 0;
        box-shadow: none;
        padding: 6px;
      }

      &[class$="-ValueContainer"] {
        padding: 0 10px !important;
      }

      &[class$="-placeholder"] {
        color: #b5b5c3;
      }
    }
  }

  .pay-buttons {
    border-width: 2px;
    background-color: white;
    color: #000;

    &:hover {
      border-color: rgba(24, 125, 228, 1);
    }

    &.bg-icon {
      background-image: url("../../../media/payment-methods/card_black.png");
    }
  }

  .google-pay-button,
  .apple-pay-button {
    border-radius: 0.42rem;
    min-height: 54px;
  }

  /* starts, react-credit-cards */
  .react-credit-cards {
    .form-control {
      padding-right: 6px !important;
    }
  }
  /* end, react-credit-cards */
  
}

@media (max-width: 991px) {
  .layout-classic .login-1 .center-vertical-horizontal {
    transform: none;
    position: static;
  }
}

/* end, layout classic */
