// tsys cash-discount-modal

// mixins
@mixin cash-discount-modal-font {
  font-size: 16px;
  font-weight: 600;
  @media (min-width: $md) {
    font-size: 20px;
    font-weight: 700;
  }
}

// default
&.cash-discount-modal {
  @include cash-discount-modal-font;
  text-align: center;
  .modal-content {
    padding: 4px 0;
    border-radius: 0;
    .pay-by {
      padding: 6px 0 0 0;
      button {
        @include cash-discount-modal-font;
        
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        
        border: 1px solid rgba(0, 0, 0, 0.11);
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
        &:hover{
          box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
        }
        border-radius: 12px;

        padding: 19px;
        margin: 16px 0;

        .svg-icon{
          svg{
            width: 40px;
            height: auto;
            path{
              fill: currentColor;
            }
          }
        }

        .title {
          flex-grow: 1;
          text-align: left;
          padding: 0 16px;
        }

        .amount{
          color: var(--primary-color);
        }

      }
    }
  }
}