div[class*="layout-"] {
  .bank-pay-button {
    margin-top: 12px;
    .btn {
      &.btn-primary {
        padding-left: 6px;
        padding-right: 6px;
        &.btn-white{
          background-color: white !important;
          border-color: #3C4043 !important;
        }
      }
    }
  }
}