.form-group {
  .input-group-prepend {
    .input-group-text {
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
    }
  }
  .input-group-append {
    .input-group-text {
      border-top-right-radius: 24px !important;
      border-bottom-right-radius: 24px !important;
    }
  }
  .input-group-text {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.c-separator {
  hr {
    border-top: 3px dashed rgba(0, 0, 0, 0.1);
  }
}
.logo-circle {
  background: #fff;
  border-radius: 100%;

  img {
    border-radius: 100%;
    border: 2px solid #fff;
    height: 74px;
    width: 74px;
  }

}

.fixed-bg-image,
.fixed-bg-color {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  max-height: 100vh;
  width: 100vw;
  min-height: 100vh;
}

.fixed-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.pay-button {
  margin-top: 24px;
  margin-bottom: 0;
}

.react-read-more-read-less,
.react-read-more-read-less-less {
  color: #3699ff;
}

.product-image {
  margin-top: 21px;
}

#input-address {
  margin-bottom: 6px;
}

#input-amount {
  .input-group-text {
    min-width: 48px;
    justify-content: center;
  }

  + #input-address {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.payment-recurring-msg + #input-address,
.form-group.question {
  margin-bottom: 6px;
}

.pr-example-amount label,
.due-date label {
  margin-bottom: 0;
}

.payment-plan {
  .period label,
  .installments label {
    margin-bottom: 0;
  }
}

.label.viewed {
  font-size: 12px;
  padding: 3px 11px;
  height: auto;
}

div[class*="layout-"] .btn.btn-primary.btn-paynow {
  padding-right: 6px;
}

.c-selectbox div[class$="-MenuList"] {
  max-height: 220px;
}

div[class*="layout-"] {
  .input-group.validation-error {
    border-color: #fa6160;
  }

  .validation-error .c-selectbox .css-1okebmr-indicatorSeparator {
    background-color: #fa6160;
  }

  .input-group.validation-error .form-control,
  .validation-error-message {
    color: #fa6160 !important;
  }

  .validation-error .c-selectbox {
    div[class$="-placeholder"],
    .css-tlfecz-indicatorContainer {
      color: #fa6160 !important;
    }
  }

  .input-group.validation-error {
    .svg-icon svg g [fill] {
      fill: #fa6160 !important;
    }

    .form-control {
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::-webkit-input-placeholder {
        color: #fa6160;
      }
    }
  }
}

.product-image {
  height: 200px;
  background: url() no-repeat center;
  background-size: cover;
  border-radius: 20px;
}

div[class*="layout-"] .subscription .btn.btn-outline-danger {
  border-color: #fa6160;
  color: #fa6160;

  i {
    color: #fa6160;
  }

  &:hover:not(.btn-text):not(:disabled):not(.disabled),
  &:focus:not(.btn-text),
  &.focus:not(.btn-text) {
    background-color: #fa6160;
    border-color: #fa6160;
  }

  &:hover:not(.btn-text):not(:disabled):not(.disabled),
  &:focus:not(.btn-text),
  &.focus:not(.btn-text),
  &:hover:not(.btn-text):not(:disabled):not(.disabled) i,
  &:focus:not(.btn-text) i,
  &.focus:not(.btn-text) i {
    color: #fff;
  }
}
