/* starts, layout retro */

.layout-retro {

  .login-signin {
    background: rgba(225, 225, 225, 1);
    border-radius: 20px;
    padding: 20px 20px 24px 20px;
  }

  .logo-circle {
    margin-bottom: -37px;
  }

  .form-control {
    border-radius: 50rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  #input-note.form-control {
    border-radius: 24px !important;
  }

  .input-group {
    border:1px solid transparent;
    border-radius: 50rem;
  }

  // .form-aib .input-group .form-control.valid + .input-group-pill .svg-icon svg g [fill] {
  //   fill: rgb(67, 176, 42) !important;
  // }
  // .form-aib .input-group .form-control.invalid + .input-group-pill .svg-icon svg g [fill] {
  //   fill: #fa6160 !important;
  // }

  .form-group {
    .c-selectbox {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important;
      padding-right: 2.5px !important;
      padding-left: 0px !important;
      border: 1px solid #e5eaee;
      div[class$="-control"] {
        background: transparent;
        border: 0;
        box-shadow: none;
        padding-left: 18px;
      }
      [class$="-indicatorContainer"] {
        color: inherit;
      }
    }
  }

  .c-toggle label p {
    margin: 0;
  }

  .btn.btn-primary,
  .btn.btn-secondary {
    padding: 0.75rem 3.75rem;
    border-radius: 2rem;
  }
  .btn.btn-primary {
    background-color: #3699ff;
    border-color: #3699ff;
    padding: 10px 6px 10px 45px;
    min-height: 46px;
  }
  .btn.btn-primary.no-icon {
    padding-right: 45px;
  }

  .c-separator {
    color: #fff;
    hr {
      border-color: #fff;
    }
  }

  .google-pay-button,
  .apple-pay-button {
    min-height: 48px;
  }

  .svg-icon{
    svg{
      g{
        [fill]{
          fill:#B5B5C3;
        }
      }
    }
  }

  .svg-icon.svg-icon-white{
    svg{
      g{
        [fill]{
          fill:#B5B5C3 !important;
        }
      }
    }
  }

  /* starts, react-credit-cards */
  .react-credit-cards {
    .form-control {
      padding-right: 2px !important;
    }
  }
  /* end, react-credit-cards */
}

/* end, layout retro */
