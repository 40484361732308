//
// react-select-async-paginate
//

// Dyanmic Currency Scenario
.dynamic-currency{
  &.c-selectbox{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .custom-async-select__control {
    .custom-async-select__indicators{
      .custom-async-select__indicator-separator{
        order: 2;
        z-index: 1;
      }
    }
    .custom-async-select__value-container{
      padding-right: 0  !important;
      .custom-async-select__single-value{
        position: relative;
        transform: none;
        max-width: none;
        margin: 0;
      }
    }
  }
}

// layout-in-app, Dyanmic Currency Scenario
.layout-in-app{
  .dynamic-currency{
    &.c-selectbox{
      padding: 0;
    }
    .custom-async-select__control {
      border: 0;
      background-color: transparent;
      padding: 0 10px 0 0;
      .custom-async-select__value-container{
        padding: 0;
        left: 6px;
        .custom-async-select__single-value{
          margin: 0;
          top:1px;
          font-size: 62px !important;
          letter-spacing: -1px;
          line-height: 1;
          color: #767779 !important;
          font-weight: 400;
          font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
        }
      }
      .custom-async-select__indicators{
        position: absolute;
        left: calc(50% - 18px);
        bottom: -20px;
        flex-direction: row;
        .custom-async-select__indicator-separator{
          width: 0;
        }
        .custom-async-select__indicator{
          padding-left: 5px;
          svg{
            stroke-width: 1px;
            path{
              fill:#767779;
            }
          }
        }
      }
    }
  }
  // amount has value
  .amount-has-value{
    .dynamic-currency{
      .custom-async-select__control {
        .custom-async-select__value-container{
          .custom-async-select__single-value{
            //color: #000 !important;
          }
        }
      }
      .custom-async-select__indicators{
        .custom-async-select__indicator{
          svg{
            path{
              fill:#000;
            }
          }
        }
      }
    }
  }
}