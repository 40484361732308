// Amount

.components {
  &.payment {
    &.amount {
      .title {
        padding: 34px 0 10px 0;
        font-size: 26px;
        line-height: 0.85;
        text-align: center;
        color: #acacac;
      }
    }
  }
}
