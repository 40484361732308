// Subscription Form

.components {
  &.subscription-form {
    
    .payment-form-loading-overlay {
      z-index: 1;
      @include media-breakpoint-lg {
        left: auto;
        width: 40vw;
      }
    }

  }
}