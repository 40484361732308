// links
.link-primary{
    color: var(--primary-color);
}

//shimmer
.shimmer {
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f833 0%,
      #edeef133 20%,
      #f6f7f833 40%,
      #f6f7f833 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    position: relative;
  
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -400px 0;
    }
    100% {
      background-position: 400px 0;
    }
  }