/* starts, layout in-app */

$amount-color: #767779;
$amount-has-value-color: #000;
$f-size-01: 16.5px;
$line-height-01: 1.1;

.layout-in-app {
  background-color: #33ca8a;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  font-size: $f-size-01  !important;
  line-height: $line-height-01;
  //font-weight: 700;

  .form-control {
    font-size: 17px;
    line-height: $line-height-01;
    color: #000 !important;
    padding: 15px;
  }

  .input-group-text {
    background-color: #fff;

    .svg-icon.svg-icon-white svg g [fill] {
      fill: #b5b5c3 !important;
    }
  }

  *:focus {
    outline: none;
  }

  .top-head {
    padding: 0;
    min-height: 139px;
  }

  .logo-circle {
    margin-bottom: 13.5px;
    border-width: 0;
    margin-top: 30px;

    img {
      height: 39.5px;
      width: 39.5px;
    }
  }

  .top-head h3 {
    font-size: 1.19174rem;
    font-weight: 800;
  }

  .login-signin {
    background-color: #fafafc !important;
    padding: 18px 18px 21px 18px;

    &.text-dark {
      color: #000 !important;
    }
  }

  .form #input-amount {
    margin-bottom: 15px !important;

    p.text {
      text-align: center;
      color: #007aff;
    }

    .validation-error-message {
      text-align: center;
    }

    .input-group-text,
    .form-control {
      background-color: transparent;
    }

    .input-group {
      align-items: center;
      justify-content: center;
      padding-left: 0.75px;

      .input-group-text,
      input[type="number"] {
        padding: 0.5px;
        font-size: 63.8px !important;
        line-height: 1;
        color: $amount-color  !important;
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
      }

      .auto-size-input {
        flex-grow: initial;

        //width: auto;
        input[type="number"] {
          //min-width: 136px;
          border: 0 !important;
          height: auto !important;
          background-color: transparent;
          box-sizing: content-box;
          display: inline;

          &::-webkit-input-placeholder {
            color: $amount-color;
            opacity: 1;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: $amount-color;
            opacity: 1;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: $amount-color;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: $amount-color;
            opacity: 1;
          }

          &::-ms-input-placeholder {
            /* Edge */
            color: $amount-color;
            opacity: 1;
          }

          &:placeholder-shown {
            /* Standard one last! */
            color: $amount-color;
            opacity: 1;
          }

        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          margin: 0;
          appearance: none;
        }

      }
    }

    // starts : input has value
    &.amount-has-value {

      .input-group-text,
      input[type="number"] {
        color: $amount-has-value-color  !important;
      }
    }

    // end : input has value

  }

  .advanced-options {
    margin: 1.75rem 0;

    .toggle {
      font-weight: bold;
      font-size: 12px;
    }

    .options {
      margin-top: 1.75rem;
    }

    .payment-plan {
      .payment-recurring-msg {
        margin-bottom: 18px;
        padding: 2px 4px 0 4px;

        small {
          font-size: 15px;
        }
      }
    }
  }

  // starts: Mobile select
  .mobile-select {
    position: relative;
    z-index: 0;
    margin: 9px 0;
    width: 100%;

    .custom {
      background-color: white;
      border-radius: 0.42rem;
      padding: 15px;

      .prompt {
        color: #888;
        font-size: 13px;
      }

      .select {
        color: #000;
        padding-top: 6px;
      }

      .icon {
        position: absolute;
        bottom: 12px;
        right: 12px;
        color: #b5b5c3;
        z-index: 0;
      }
    }

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
      z-index: 1;
    }
  }

  // end: Mobile select

  // starts: Mobile input
  .mobile-input {
    position: relative;
    z-index: 0;
    margin: 9px 0;
    width: 100%;

    .custom {
      background-color: white;
      border-radius: 0.42rem;
      padding: 15px;

      .title {
        color: #888;
        font-size: 13px;
      }

      .field {
        color: #000;
        padding-top: 6px;

        .form-control {
          border:0;
          padding: 0;
          height: auto;
        }
      }
    }

    
  }

  // end: Mobile input

  .pay-button {
    margin-top: 1.75rem;

    .btn {
      &.btn-primary {
        border-radius: 10px;
        font-size: 19px;
        padding-top: 10.5px;
        padding-bottom: 10.5px;

        .svg-icon {
          display: none;
        }

        .font-weight-bolder {
          font-weight: 800 !important;
        }
      }
    }
  }

  /* starts, react-credit-cards */
  .react-credit-cards {
    .form-control {
      padding-right: 2px !important;

      .c-feild {
        label {
          min-height: 17px;

          input {
            font-size: 17px;
            line-height: $line-height-01;
            color: #000 !important;
          }
        }
      }
    }
  }

  /* end, react-credit-cards */

}

/* end, layout mobile */