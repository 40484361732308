//
// react-toastify
//

.Toastify__toast {
  border-radius: 10px !important;
  padding: 0 20px !important;
  text-align: center !important;
}
.Toastify__toast-body {
  font-weight: 500 !important;
  font-size: 16px !important;
}
.Toastify__toast--success{
  background-color: #37db70 !important;
}
.Toastify__toast--error {
  background-color: #fa6160 !important;
}