// NFC

.components {
  &.payment {
    &.nfc {
      font-size: 22px;
      line-height: 1.25;
      max-width: 298px;
      margin: auto;
      text-align: center;
      .wifi {
        background-color: #e9edf1;
        width: 232px;
        height: 232px;
        margin: 42px auto 34px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        svg {
          fill: #767779;
        }
      }
    }
  }
}
