// Shop V2

$border-radius-16: 16px;
$box-size: 232px;
$your-cart-img-size: 84px;
$your-cart-and-QV-width-tablet: calc(100vw - 20px - 21px - 20px - 21px);

@keyframes showYourCart {
  0% {
    top: -48px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}

@mixin bgImg($size: $box-size) {
  height: $size;
  border-radius: $border-radius-16;
  background: url() no-repeat 50%;
  background-size: cover;
  display: block;
}

html {
  @media (max-width: $md-b) {
    touch-action: pan-x pan-y;
  }
}
body {
  @media (max-width: $md-b) {
    touch-action: pan-x pan-y;
    &.m-qv-open {
      //max-width: 100vw;
      overflow: hidden;
    }
  }
}

.shop {
  &.v2 {
    // starts : setting the max width for the Retro
    .login {
      &.login-3 {
        .login-form {
          max-width: 1000px;
          p {
            &.caption {
              padding: 0 42px;
            }
          }
        }
      }
    }
    // end : setting the max width for the Retro

    // starts : Default pdp
    .pdp {
      text-align: center;
      // position: relative;
      padding: 0 0 24px 0;
      @media (min-width: $lg) {
        padding: 0 24px 24px 24px;
      }
      .row {
        // background-color: black;
      }
      .products {
        clear: both;
      }
      .product {
        margin: 12px 0;
        .box {
          display: block;
          // height: 100%;
          position: relative;
          font-size: 15px;
          line-height: 1.25;
          color: #212121 !important;
          //transition: all 0.1s ease-in-out !important;
          padding-bottom: 0;
          @media (min-width: $md) {
            max-width: $box-size;
          }
          @media (min-width: $lg) {
            min-width: $box-size;
            display: inline-block;
          }
          .img {
            background-color: #fff;
            border-radius: $border-radius-16;
            margin: 0 0 24px 0;
            padding: 0;
            position: relative;
            &::after {
              background-color: rgba(0, 0, 0, 0.7);
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: " ";
              border-radius: $border-radius-16;
              opacity: 0;
            }
            span {
              @include bgImg;
            }
            span {
              @media (max-width: $lg-b) {
                height: 212px;
              }
              @media (max-width: $md-b) {
                height: 293px;
              }
            }
            button {
              position: absolute;
              top: calc(50% - 19.19px);
              left: calc(50% - 56.4px);
              color: #007aff;
              background-color: white;
              opacity: 0;
              z-index: 1;
            }
            &:hover {
              &::after,
              button {
                opacity: 1;
              }
            }
          }
          .img,
          .name,
          .price {
            display: block;
          }
          .name,
          .price {
            padding: 0 24px;
          }
          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .price {
            margin: 12px 0 0 0;
            color: #00a651;
            font-size: 20px;
          }
        }
        .details {
          transition: all 0.4s ease-in-out !important;
          opacity: 0;
          position: relative;
          background-color: #fff;
          width: $your-cart-and-QV-width-tablet;
          z-index: -1;
          height: 0;
          border-radius: $border-radius-16;
          text-align: left;
          left: 0;
          @media (max-width: $md-b) {
            bottom: -40vh;
            width: 100vw;
          }
          @media (min-width: $md) {
            top: -103px;
          }
          @media (min-width: $lg) {
            width: 827px;
            left: 20px;
          }
          .qv {
            padding: 32px;
            @media (min-width: $md) {
              padding: 48px;
            }
            .btn-close {
              opacity: 0;
            }
            .col-right {
              @media (max-width: $md-b) {
                //padding-top: 24px;
              }
            }
          }
          .p-carousel {
            background-color: #f3f6f9;
            border-radius: $border-radius-16;
            padding: 0;
            @media (min-width: $lg) {
              min-width: $box-size;
              max-width: $box-size;
            }
            margin: auto;
            .carousel-item {
              span {
                @include bgImg;
              }
              span {
                @media (max-width: $lg-b) {
                  height: 196px;
                }
              }
            }
            img {
              max-width: 100%;
              height: $box-size;
            }
            .carousel-control-prev {
              left: -40px;
              @media (max-width: $lg-b) {
                left: -32px;
              }
            }
            .carousel-control-next {
              right: -40px;
              @media (max-width: $lg-b) {
                right: -32px;
              }
            }
            .carousel-control-prev,
            .carousel-control-next {
              width: 36px;
            }
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
              background-image: url(../../../../media/shop/v2/icons/arrow-left.svg);
              width: 36px;
              height: 36px;
            }
            .carousel-control-next-icon {
              background-image: url(../../../../media/shop/v2/icons/arrow-right.svg);
            }
            &.hide-arrows {
              [class^="carousel-control-"] {
                display: none;
              }
            }
          }
          .name {
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 10px;
          }
          .price {
            color: #00a651;
            font-size: 20px;
            padding-bottom: 13px;
          }
          .about {
            padding-bottom: 23px;
          }
          .size {
            padding-bottom: 1px;
          }
          .form-control {
            background-color: #f3f6f9;
            text-align: left;
            &.quantity {
              height: 46px;
            }
          }
          + .m-close {
            display: none;
          }
        }
        .triangle {
          position: absolute;
          top: -17px;
          left: 92.5px;
          opacity: 0;
          @media (min-width: $lg) {
            left: 102.5px;
          }
          svg {
            height: 18px;
            width: 27px;
          }
        }

        &:nth-child(3n + 2) {
          .details {
            @media (min-width: $md) {
              left: -30.9vw;
            }
            @media (min-width: $lg) {
              left: -277px;
            }
            .triangle {
              @media (min-width: $md) {
                left: 43vw;
              }
              @media (min-width: $lg) {
                left: 400px;
              }
            }
          }
        }

        &:nth-child(3n + 3) {
          .details {
            @media (min-width: $md) {
              left: -61.7vw;
            }
            @media (min-width: $lg) {
              left: -574px;
            }
            .triangle {
              @media (min-width: $md) {
                left: 73.75vw;
              }
              @media (min-width: $lg) {
                left: 697.5px;
              }
            }
          }
        }

        &.show-details {
          @media (max-width: $md-b) {
            .m-close {
              background-color: rgba(0, 0, 0, 0.7);
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: " ";
              z-index: 1;
              display: block;
            }
          }
          .box {
            padding-bottom: 0;
          }
          .details {
            top: -55px;
            opacity: 1;
            z-index: 1;
            height: auto;
            @media (max-width: $md-b) {
              position: fixed;
              left: 0;
              z-index: 2;
              bottom: 0;
              top: auto;
              border-radius: 0;
              touch-action: pan-x pan-y;
              .qv {
                max-height: 80vh;
                //min-height: 80vh;
                overflow: auto;
                .triangle {
                  display: none;
                }
                .btn-close {
                  bottom: auto;
                  top: 0;
                  right: 0;
                  left: auto;
                  height: auto;
                  button {
                    background: transparent;
                    box-shadow: none;
                    bottom: 24px;
                    padding: 0 6px 0 0;
                    span {
                      &.text {
                        display: none;
                      }
                      &.icon {
                        color: rgba(0, 0, 0, 0.6);
                      }
                    }
                  }
                }
              }
            }
            .triangle,
            .btn-close {
              opacity: 1;
            }
            .btn-close {
              @media (min-width: $md) {
                button {
                  span {
                    &.icon {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .filter {
        position: relative;
        min-width: 266px;
        @media (max-width: $md-b) {
          max-width: 266px;
          margin: 0 auto;
        }
        @media (min-width: $md) {
          position: absolute;
          top: 99px;
          left: 41px;
        }
        @media (min-width: $lg) {
          top: 104px;
          left: 118.75px;
        }
        &.form-group .c-selectbox div[class$="-control"] {
          min-height: 45px;
          padding-left: 45px;
          font-weight: 500;
          font-size: 15px;
          line-height: 24px;
          color: #444444;
          div[class$="-placeholder"] {
            color: #444444;
          }
          div {
            justify-content: center;
          }
        }
        .icon {
          position: absolute;
          width: 30px;
          left: 18px;
          top: 9px;
          z-index: 1;
          .stroke {
            stroke: #83beff;
          }
          .stroke2 {
            stroke: #deeeff;
            fill: #deeeff;
          }
        }
      }
      .btn-cart {
        position: relative;
        min-width: 266px;
        @media (max-width: $md-b) {
          max-width: 266px;
        }
        @media (min-width: $md) {
          position: absolute;
          top: 98px;
          right: 41px;
        }
        @media (min-width: $lg) {
          top: 107px;
          right: 118.75px;
        }
        height: 46px;
        border: 0;
        background: #ffffff;
        border-radius: 100px;
        text-align: left;
        padding: 0 16px 0 15px;
        .amount {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
        }
        .icon {
          svg {
            height: 21px;
          }
        }
        .count {
          span {
            width: 30px;
            height: 30px;
            background-color: #007aff;
            display: flex;
            color: #fff;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
          }
        }
      }
      .your-cart {
        clear: both;
        padding: 24px;
        @media (min-width: $md) {
          padding: 48px;
        }
        background-color: #fff;
        border-radius: $border-radius-16;
        width: 827px;
        text-align: left;
        margin: 0 auto 34px auto;
        animation: showYourCart 0.2s linear;
        position: relative;
        z-index: 0;
        width: $your-cart-and-QV-width-tablet;
        @media (min-width: $lg) {
          width: 827px;
        }
        .bold {
          font-weight: 500;
        }
        .details {
          display: flex;
          flex-direction: column;
          height: 100%;
          h4 {
            font-size: 20px;
            font-weight: 700;
            padding-bottom: 20px;
          }
          .items {
            .item {
              background-color: #f3f6f9;
              border-radius: 6px;
              margin: 0 0 27px 0;
              position: relative;
              z-index: 1;
              padding: 10px;
              img {
                max-width: 100%;
                height: 84px;
              }
              .img {
                span {
                  @include bgImg($size: $your-cart-img-size);
                  border-radius: 0;
                  width: $your-cart-img-size;
                }
              }
              .name {
                padding-bottom: 2px;
                font-size: 14px;
              }
              .price {
                color: #00a651;
                font-size: 15px;
                padding-bottom: 7px;
              }
              .close {
                background-color: rgba(0, 0, 0, 0.12);
                border-radius: 50%;
                color: white;
                width: 19px;
                height: 19px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: -8px;
                top: -8px;
                span {
                  font-size: 16px;
                }
              }
            }
          }
          table {
            font-size: 15px;
            tr {
              td {
                &:last-child {
                  font-weight: 500;
                }
              }
              &.total {
                font-weight: 500;
                border-top: 1px solid rgba(68, 68, 68, 0.44);
                td {
                  padding-top: 22px;
                }
              }
            }
          }
        }
        .col-left {
          //background-color: red;
        }
        .col-right {
          //text-align: right;
          .delivery {
            & + .payment {
              padding-top: 20px;
            }
          }
          .form-control {
            &.border-0 {
              border: 1px solid #e5eaee !important;
            }
          }
          .c-separator {
            color: #464e5f;
            hr {
              border-color: rgba(0, 0, 0, 0.1);
            }
          }
          .pay-button {
            margin-top: 18px;
          }
        }
      }
      .quantity {
        max-width: 100%;
        overflow: hidden;
        &.input-number {
          display: flex;
          min-height: 46px;
          background-color: #f3f6f9;
          border-radius: 20px;
          justify-content: center;
          align-items: center;
          input {
            -webkit-appearance: none;
            width: 33px;
            max-width: 33px;
            border: 0;
            background-color: transparent;
            text-align: center;
            &:focus,
            &:focus-visible {
              border: 0;
            }
            &:focus-visible {
              outline: none;
            }
          }
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
          button {
            border: 0;
            background: #444444;
            padding: 0;
            border-radius: 100px;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            flex: 0 0 36px;
            margin: 5px;
            font-size: 20px;
          }
        }
        &.v2 {
          &.input-number {
            background-color: white;
            min-height: 25px;
            display: inline-flex;
            input {
              width: 22px;
              max-width: 22px;
            }
            button {
              width: 20px;
              height: 20px;
              flex: 0 0 20px;
              margin: 3px;
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
      .btn-close {
        position: absolute;
        left: calc(50% - 52px);
        height: 26px;
        overflow: hidden;
        bottom: -26px;
        transition: all 0.4s;
        z-index: 0;
        button {
          height: 52px;
          position: relative;
          bottom: 31px;
          background-color: #fff;
          border-radius: 14px;
          text-align: center;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
          display: flex;
          align-items: flex-end;
          padding: 0px 30px;
          color: #444;
          font-size: 16px;
          font-weight: 400;
          &:hover {
            color: #007aff;
          }
        }
      }
      .product-letter {
        font-size: 60px !important;
      }
    }
    // end : Default pdp

    // starts : default fixed header
    &.fixed-header {
      &.show {
        .top-head {
          background-color: rgba(225, 225, 225, 0.85);
          position: fixed;
          animation: fadeInDown 0.3s ease-out forwards;
          width: 100%;
          height: 94px;
          top: 0;
          left: 0;
          z-index: 2;
          @media (max-width: $md-b) {
            justify-content: left;
            padding-left: 9px;
          }
          .logo-circle {
            @media (max-width: $md-b) {
            }
            margin-bottom: 0;
          }
        }

        .pdp {
          .btn-cart,
          .filter {
            top: 24px;
            position: fixed;
            z-index: 2;
            animation: fadeInDown 0.3s ease-out forwards;
            @media (max-width: $md-b) {
              top: 27.5px;
            }
            @media (min-width: $md) {
            }
          }
          .btn-cart {
            @media (max-width: $md-b) {
              height: 39px;
              left: auto;
              min-width: auto;
              right: 10px;
              width: 39px;
              .icon {
                svg {
                  width: auto;
                  height: 16px;
                  position: relative;
                  z-index: 0;
                  left: -9px;
                }
              }
              .amount {
                display: none;
              }
              .count {
                position: absolute;
                top: -5px;
                right: -5px;
                span {
                  width: 20px;
                  height: 20px;
                  font-size: 12px;
                }
              }
            }
            @media (min-width: $md) {
              top: 24px;
              right: 52px;
            }
            @media (min-width: $lg) {
              right: auto;
              margin-left: 115px;
            }
          }
          .filter {
            @media (max-width: $md-b) {
              max-width: 221px;
              min-width: 221px;
              left: auto;
              right: 60px;
              &.form-group .c-selectbox div[class$="-control"] {
                min-height: 36px;
                padding-left: 0;
              }
              .icon {
                top: 4px;
                display: none;
              }
            }
            @media (min-width: $md) {
              left: 52px;
            }
            @media (min-width: $lg) {
              left: auto;
              margin-left: 52px;
            }
          }
        }
      }
    }
    // end : default fixed header

    // starts pdp : modern layout
    .layout-modern {
      .login {
        &.login-3 {
          &.login-signin-on {
            .login-signin {
              padding: 20px 20px 24px 20px;
            }
          }
        }
      }
      .pdp {
        .product {
          .box {
            .img {
              &::after {
                background-color: rgba(0, 0, 0, 0.35);
              }
            }
            .name {
              color: #fff;
            }
          }
          .details {
            background-color: rgba(33, 33, 33, 0.95) !important;
            .p-carousel {
              .carousel-control-prev-icon,
              .carousel-control-next-icon {
                background-image: url(../../../../media/shop/v2/icons/modern/arrow-left.svg);
              }
              .carousel-control-next-icon {
                background-image: url(../../../../media/shop/v2/icons/modern/arrow-right.svg);
              }
            }
          }
          &.show-details {
            .details {
              @media (max-width: $md-b) {
                .qv {
                  .btn-close {
                    button {
                      border: 0;
                      span {
                        &.icon {
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
              .triangle {
                opacity: 0.7;
                margin-top: -2px;
              }
            }
          }
        }
        .filter {
          @media (min-width: $md) {
            top: 96px;
          }
          @media (min-width: $lg) {
            top: 103px;
          }
          .icon {
            .stroke {
              stroke: rgba(225, 225, 225, 0.5);
            }
            .stroke2 {
              stroke: rgba(225, 225, 225, 0.4);
              fill: rgba(225, 225, 225, 0.7);
            }
          }
        }
        .btn-cart {
          @media (min-width: $md) {
            top: 96px;
          }
          @media (min-width: $lg) {
            top: 103px;
          }
          height: 50px;
          border: 1px solid #fff;
          background: rgba(33, 33, 33, 0.36) !important;
          .amount {
            color: rgba(225, 225, 225, 1);
          }
          .icon {
            svg {
              .fill {
                fill: rgba(225, 225, 225, 0.5);
              }
            }
          }
          .count {
            span {
              background-color: rgba(225, 225, 225, 0.7);
              color: rgba(0, 0, 0, 0.7) !important;
            }
          }
        }
        .your-cart {
          background-color: rgba(33, 33, 33, 0.36) !important;
          border: 1px solid #fff;
          .details {
            .items {
              .item {
                background-color: rgba(33, 33, 33, 0.36) !important;
                .close {
                  background-color: rgba(33, 33, 33, 0.36) !important;
                  opacity: 0.7;
                }
              }
            }
            table {
              tr {
                &.total {
                  border-color: rgba(255, 255, 255, 1);
                }
              }
            }
          }
          .col-right {
            .form-control {
              &.border-0 {
                border-width: 0 !important;
              }
            }
            .c-separator {
              color: #fff;
              hr {
                border-color: #fff;
              }
            }
          }
        }
        .quantity {
          &.v2 {
            &.input-number {
              background-color: rgba(33, 33, 33, 0.36) !important;
              input {
                color: white;
              }
            }
          }
        }
        .btn-close {
          button {
            background-color: rgba(33, 33, 33, 0.36) !important;
            box-shadow: 0 1px 3px rgba(255, 255, 255, 0.12);
            color: #fff;
            border-color: #fff;
            &:hover {
              background-color: rgba(33, 33, 33, 0.85) !important;
            }
          }
        }
      }
    }
    // end pdp : modern layout
    // starts : modern layout fixed header
    &.fixed-header {
      &.show {
        .layout-modern {
          .top-head {
            background-color: rgba(33, 33, 33, 0.95);
          }

          .pdp {
            .filter {
              @media (max-width: $md-b) {
                &.form-group .c-selectbox div[class$="-control"] {
                  min-height: 36px;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    // end : modern layout fixed header

    // starts pdp : Classic layout

    // starts : setting the max width for the Classic
    .login {
      &.login-1 {
        .login-content,
        .login-form {
          max-width: 840px;
          width: 100%;
          @media (min-width: $lg) {
            //width: 90%;
          }
        }
        .login-content {
          padding-top: 2.2rem !important;
          padding-bottom: 2.2rem !important;
        }
        .login-form {
          padding: 20px 20px 24px 20px;
          @media (min-width: $lg) {
            padding: 20px 12px 24px 12px;
          }
        }
      }
    }
    // end : setting the max width for the Classic

    .layout-classic {
      .pdp {
        @media (min-width: $md) {
          text-align: left;
        }
        @media (min-width: $lg) {
          padding: 0 0 24px 0;
        }
        .product {
          text-align: left;
          .details {
            background-color: #f7f9fb;
            @media (min-width: $lg) {
              width: 762px;
              left: 0px;
            }
          }
          .triangle {
            svg {
              path {
                fill: #f7f9fb;
              }
            }
          }

          &:nth-child(3n + 2) {
            .details {
              @media (min-width: $lg) {
                left: -265px;
              }
              .triangle {
                @media (min-width: $lg) {
                  left: 367.5px;
                }
              }
            }
          }

          &:nth-child(3n + 3) {
            .details {
              @media (min-width: $lg) {
                left: -530px;
              }
              .triangle {
                @media (min-width: $lg) {
                  left: 627.5px;
                }
              }
            }
          }
        }
        .filter {
          @media (min-width: $md) {
            position: relative;
            top: -20.5px;
            left: 0;
            display: inline-block;
          }
          .icon {
            top: 17.5px;
          }
        }
        .btn-cart {
          @media (min-width: $md) {
            position: relative;
            top: -20.5px;
            left: 0;
            float: right;
            margin-bottom: 0 !important;
          }
          background: #f3f6f9;
          height: 66.8px;
          padding: 14.5px 16px;
          border: 1px solid #f3f6f9;
          border-radius: 11.05px;
        }
        .your-cart {
          box-shadow: 0 0 0 2px #f3f6f9;
          @media (min-width: $lg) {
            width: auto;
          }
          .details {
            .items {
              .item {
                background-color: #f3f6f9;
              }
            }
          }
          .col-right {
            .form-control {
              &.border-0 {
                border-width: 0 !important;
              }
            }
            .c-separator {
              color: #fff;
              hr {
                border-color: #fff;
              }
            }
          }
        }
        .btn-close {
          @media (min-width: $lg) {
            height: 28px;
            bottom: -28px;
          }
          button {
            @media (min-width: $lg) {
              bottom: 29px;
            }
            background-color: #f3f6f9;
          }
        }
      }
    }
    // end pdp : Classic layout
    // starts : Classic layout fixed header
    &.fixed-header {
      &.show {
        .layout-classic {
          .login-aside{
            @media (min-width: $lg) {
              z-index: 3;
            }
          }
          .top-head {
            @media (min-width: $lg) {
              background: none;
              position: static;
              animation: none;
              height: auto;
            }
          }
          .pdp {
            &::before {
              @media (min-width: $lg) {
                background-color: rgba(225, 225, 225, 0.85);
                position: fixed;
                animation: fadeInDown 0.3s ease-out forwards;
                width: 100%;
                height: 94px;
                top: 0;
                left: 0;
                z-index: 2;
                content: "";
              }
            }

            .btn-cart {
              @media (max-width: $md-b) {
                height: 38px;
                width: 38px;
                .icon {
                  svg {
                    top: -5px;
                    left: -12px;
                  }
                }
              }
              @media (min-width: $md) {
                top: 14px;
                right: 52px;
                left: auto;
              }
              @media (min-width: $lg) {
                right: 41px;
                margin-left: 0;
              }
              @media (min-width: $xxxl) {
                right: 14.65%;
              }
            }
            .filter {
              @media (min-width: $md) {
                top: 14px;
              }
              @media (min-width: $lg) {
                margin-left: 0;
              }
              @media (max-width: $md-b) {
                &.form-group .c-selectbox {
                  padding: 0 !important;
                  div[class$="-control"] {
                    padding: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
    // end : Classic layout fixed header
  }
}